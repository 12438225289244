const categories = [
    {
        id: 7,
        name: 'Biomassa',
        slug: 'biomassa-biometano',
    },
    {
        id: 8,
        name: 'Cone',
        slug: 'cone',
    },
    {
        id: 9,
        name: 'Conexão Elétrica',
        slug: 'conexao-eletrica',
    },
    {
        id: 6,
        name: 'Energia Solar',
        slug: 'energia-solar',
    },
    {
        id: 4,
        name: 'Gás natural',
        slug: 'gas-natural',
    },
    {
        id: 1,
        name: 'N/A',
        slug: 'uncategorized',
    },
];

export default categories;
