<template>
  <div
    class="home"
    :style="`background-image: url(${computedSlides[currentSlide] ? computedSlides[currentSlide].imageURL : defaultBackgroundImage})`"
  >
    <slide
      :slides="computedSlides"
      @currentSlide="updateBG"
    />
    <main v-if="sections && sections.length">
      <section
        class="section-1"
        :style="`background-image: url(${placas})`"
      >
        <article>
          <h2>{{ 'SECTION-1-TITLE' | sectionValue(sections) }}</h2>
          <p>
            {{ 'SECTION-1-DESCRIPTION' | sectionValue(sections) }}
          </p>
          <a
            v-if="sections.find((section) => section.option.includes('SECTION-1-BUTTON')).value.includes('mailto')"
            :href="'SECTION-1-BUTTON' | sectionValue(sections)"
            class="button rounded bg-orange autoSize"
          >
            Saiba mais
          </a>
          <router-link
            v-else
            tag="a"
            :to="{name: 'post', params: {slug:sections.find((section) => section.option.includes('SECTION-1-BUTTON')).value }}"
            class="button rounded bg-dark autoSize"
          >
            Saber mais
          </router-link>
        </article>
      </section>

      <section
        class="section-2"
        :style="`background-image: url(${postes})`"
      >
        <article>
          <h2>{{ 'SECTION-2-TITLE' | sectionValue(sections) }}</h2>
          <p>
            {{ 'SECTION-2-DESCRIPTION' | sectionValue(sections) }}
          </p>

          <a
            v-if="sections.find((section) => section.option.includes('SECTION-2-BUTTON')).value.includes('mailto')"
            :href="'SECTION-2-BUTTON' | sectionValue(sections)"
            class="button rounded bg-orange autoSize"
          >
            Saiba mais
          </a>
          <router-link
            v-else
            tag="a"
            :to="{name: 'post', params: {slug:sections.find((section) => section.option.includes('SECTION-2-BUTTON')).value }}"
            class="button rounded bg-orange autoSize"
          >
            Saber mais
          </router-link>
        </article>
      </section>

      <section
        class="section-3"
      >
        <article>
          <h2>{{ 'SECTION-3-TITLE' | sectionValue(sections) }}</h2>
          <div class="team">
            <router-link
              tag="div"
              class="person"
              :to="{ name: 'post', params: { slug: 'equipe' } }"
              exact
            >
              <figure>
                <img :src="logo">
              </figure>
              <span class="name">Carlos Roberto Silvestrin – CEO</span>
              <p class="short-description">
                Graduado em engenharia industrial – Eletrotécnica (1969) e de Produção (1973) pela Faculdade de Engenharia Industrial (FEI), com capacitação...
              </p>
            </router-link>

            <router-link
              tag="div"
              class="person"
              :to="{ name: 'post', params: { slug: 'equipe' } }"
              exact
            >
              <figure>
                <img :src="logo">
              </figure>
              <span class="name">Roberta Beloto Silvestrin – Diretora</span>
              <p class="short-description">
                Engenheira Química formada pela UNICAMP em 1999, com Pós Graduação em Energia e Meio Ambiente (MEDEA) pela Eni Corporate University...
              </p>
            </router-link>
          </div>
          <a
            v-if="sections.find((section) => section.option.includes('SECTION-3-BUTTON')).value.includes('mailto')"
            :href="'SECTION-3-BUTTON' | sectionValue(sections)"
            class="button rounded bg-orange autoSize"
          >
            Saiba mais
          </a>
          <router-link
            v-else
            tag="a"
            :to="{name: 'post', params: {slug:sections.find((section) => section.option.includes('SECTION-3-BUTTON')).value }}"
            class="button rounded bg-orange autoSize"
          >
            Saiba mais
          </router-link>
        </article>
      </section>
    </main>
  </div>
</template>
<script>
/* eslint-disable global-require */
import Slide from '@/components/slide.vue';
import { mapActions, mapState } from 'vuex';
import defaultSlides from '@/cache/slides';
import defaultSettings from '@/cache/settings';

export default {
    name: 'Home',
    metaInfo() {
        return {
            title: 'Cone Consultoria e Planejamento',
            meta: [
                { name: 'description', content: 'CONSULTORIA E EXCELÊNCIA A MAIS DE 30 ANOS.' },
            ],
        };
    },
    filters: {
        sectionValue(value, sections) {
            return sections.find((section) => section.option.includes(value)).value;
        },
    },
    components: {
        Slide,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.changeMenuVisibility(false);
            vm.changeIsBlog(false);
            vm.changeThemeColor('default');
        });
    },
    data() {
        return {
            currentSlide: 0,
            bgSection2: require('@/assets/second-section-bg.png'),
            placas: require('@/assets/placas.svg'),
            postes: require('@/assets/postes.svg'),
            defaultBackgroundImage: require('@/assets/bg-1.png'),
            logo: require('@/assets/logo-horizontal.svg'),
            defaultSlides,
            defaultSettings,
        };
    },
    computed: {
        ...mapState('posts', ['slides', 'settings']),
        computedSlides() {
            const { slides } = this;
            const currentSlides = slides.length ? slides : this.defaultSlides;
            const sortedSlides = currentSlides.sort((a, b) => a.position - b.position);
            return sortedSlides.map((slide) => ({
                title: slide.title,
                description: slide.description,
                imageURL: slide.url,
                buttons: slide.buttons,
            }));
        },
        sections() {
            return this.settings.length ? this.settings : this.defaultSettings;
        },
    },
    created() {
        document.documentElement.scrollTop = 0;
        this.getSettings();
        this.changeLoaderVisibility(true);
        if (!this.slides.length) {
            this.getSlides().then((slides) => {
                if (!slides.length) throw new Error('Slides not found.');
                this.changeLoaderVisibility(false);
            });
        } else this.changeLoaderVisibility(false);
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'changeIsBlog', 'changeLoaderVisibility', 'changeThemeColor']),
        ...mapActions('posts', ['getSlides', 'getSettings', 'getPosts', 'getPages']),
        updateBG(value) {
            this.currentSlide = value;
        },
    },
};
</script>
