const users = [
    {
        id: 3,
        name: 'Cone Consultoria',
        url: 'http://coneconsultoria.com.br',
        description: '',
        link: 'https://admin-cone.coneconsultoria.com.br/author/cone/',
        slug: 'cone',
        avatar_urls: {
            24: 'https://secure.gravatar.com/avatar/1cc05d16617024f9f189600906580d91?s=24&d=mm&r=g',
            48: 'https://secure.gravatar.com/avatar/1cc05d16617024f9f189600906580d91?s=48&d=mm&r=g',
            96: 'https://secure.gravatar.com/avatar/1cc05d16617024f9f189600906580d91?s=96&d=mm&r=g',
        },
        meta: [

        ],
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users',
                },
            ],
        },
    },
];

export default users;
