const posts = [
    {
        id: 297,
        date: '2024-09-04T23:57:34',
        slug: 'descarbonizacao',
        status: 'publish',
        type: 'post',
        title: {
            rendered: 'DESCARBONIZAÇÃO',
        },
        content: {
            rendered: '\n<ul><li>Identificação de oportunidades e networking junto aos potenciais produtores de combustíveis de baixo carbono (Hidrogênio Verde, SAF, originados de fontes de biomassa e de biometano)</li><li>Assessoria na estruturação e desenvolvimento de projetos no ambiente regulatório vigente</li><li>Assessoria nas propostas de revisão, inovação e encaminhamento das questões regulatórias (Executivo e Legislativo), para avançar na transição energética e descarbonização dos combustíveis de origem fóssil para estruturação e desenvolvimento de projetos</li><li>Assessoria junto para obtenção de financiamento, principalmente Fundo do Clima do BNDES</li></ul>\n',
            protected: false,
        },
        excerpt: {
            rendered: '<p>Oferecemos assessoria para identificar oportunidades em combustíveis de baixo carbono, estruturar projetos regulatórios e garantir financiamento sustentável. Avance na transição energética com nossa expertise em hidrogênio verde, biometano e muito mais.</p>\n',
            protected: false,
        },
        author: 3,
        featured_media: 312,
        categories: [
            1,
        ],
        tags: [

        ],
    },
    {
        id: 265,
        date: '2020-12-11T11:41:57',
        slug: 'biometano',
        status: 'publish',
        type: 'post',
        title: {
            rendered: 'Biometano',
        },
        content: {
            rendered: '\n<ul><li>Networking junto a produtores do setor Sucroenergético (biomassa/biogás/biometano)</li><li>Assessoria na estruturação e desenvolvimento de projetos</li><li>Assessoria regulatória para enquadramento de projetos (mapa regulatório)</li><li>Assessoria junto ao BNDES, principalmente, para obtenção de financiamento do Fundo do Clima</li><li>Avaliação e estruturação regulatória de projetos para atendimento de indústrias, datacenters, inclusive, projetos para inserção de biometano na rede de distribuição (blend) </li></ul>\n',
            protected: false,
        },
        excerpt: {
            rendered: '<p>Assessoramos análises estratégicas de investimentos voltados para a geração de energia elétrica, seja geração termoelétrica, cogeração de enegria, geração distribuída ou que necessitem de suprimento deste combustível para consumo industrial, bem como produção para venda ao mercado.</p>\n',
            protected: false,
        },
        author: 3,
        featured_media: 323,
        categories: [
            7,
        ],
        tags: [

        ],
    },
    {
        id: 203,
        date: '2020-12-01T13:46:01',
        slug: 'conexao-eletrica',
        status: 'publish',
        type: 'post',
        title: {
            rendered: 'Conexão Elétrica',
        },
        content: {
            rendered: '\n<ul><li>Assessoria no processo de conexão elétrica nas Redes de Distribuição (Concessionárias) e nas Redes de Transmissão do Sistema Interligado Nacional (ONS, transmissoras)</li><li>Assessoria destacada no enquadramento regulatório (mapa regulatório) e desenvolvimento de projetos de datacenters para conexão na rede de distribuição e de transmissão até 230kV</li></ul>\n',
            protected: false,
        },
        excerpt: {
            rendered: '<p>Assessoramos analises estratégicas de investimentos para viabilidade de conexões elétricas de empreendimentos, junto ao Sistema Interligado Nacional e/ou ao sistema de distribuição local.</p>\n',
            protected: false,
        },
        author: 3,
        featured_media: 316,
        categories: [
            9,
        ],
        tags: [

        ],
    },
    {
        id: 201,
        date: '2020-12-01T13:45:28',
        slug: 'regulatorio',
        status: 'publish',
        type: 'post',
        title: {
            rendered: 'Regulatório',
        },
        content: {
            rendered: '\n<ul><li>Assessoria para encaminhamento de procedimentos regulatórios para de Unidades Geradoras, industrias e datacenters, bem como Produtores de Biometano</li><li>Avaliação e estruturação regulatória de projetos para atendimento de indústrias, datacenters, bem como projetos de geração de energia, projetos para inserção de biometano na rede de distribuição </li></ul>\n',
            protected: false,
        },
        excerpt: {
            rendered: '<p>Assessoria para encaminhamento de procedimentos regulatórios para de Unidades Geradoras, industrias e datacenters, bem como Produtores de Biometano Avaliação e estruturação regulatória de projetos para atendimento de indústrias, datacenters, bem como projetos de geração de energia, projetos para inserção de biometano na rede de distribuição </p>\n',
            protected: false,
        },
        author: 3,
        featured_media: 314,
        categories: [
            9,
        ],
        tags: [

        ],
    },
    {
        id: 192,
        date: '2020-12-01T05:11:07',
        slug: 'gas-natural',
        status: 'publish',
        type: 'post',
        title: {
            rendered: 'Gás Natural',
        },
        content: {
            rendered: '\n<ul><li>Assessoria na estruturação e desenvolvimento de projetos de Termoelétrica, Cogeração e Geração Distribuída</li><li>Assessoria regulatória para enquadramento do projeto junto aos órgãos reguladores (ANEEL, ANP, ARSESP) e Agentes de Financiamento (BNDES).</li><li>Avaliação e estruturação regulatória de projetos para atendimento de indústrias, datacenters, inclusive, projetos para inserção de biometano na rede de distribuição (blend)</li></ul>\n',
            protected: false,
        },
        excerpt: {
            rendered: '<p>Assessoramos análises estratégicas de investimentos voltados para a geração de energia elétrica, seja geração termoelétrica, cogeração de enegria, geração distribuída ou que necessitem de suprimento deste combustível.</p>\n',
            protected: false,
        },
        author: 3,
        featured_media: 317,
        categories: [
            4,
        ],
        tags: [

        ],
    },
    {
        id: 190,
        date: '2020-12-01T05:10:47',
        slug: 'bioeletricidade',
        status: 'publish',
        type: 'post',
        title: {
            rendered: 'BIOELETRICIDADE',
        },
        content: {
            rendered: '\n<ul><li>Networking junto a produtores do setor Sucroenergético (biomassa/biogás/biometano)</li><li>Assessoria na estruturação e desenvolvimento de projetos</li><li>Assessoria regulatória para enquadramento de projetos</li><li>Assessoria junto ao BNDES, principalmente, para obtenção de financiamento do Fundo do Clima</li><li>Avaliação de projetos</li></ul>\n',
            protected: false,
        },
        excerpt: {
            rendered: '<p>Assessoramos análises estratégicas de investimentos voltados para a geração de bioeletricidade, seja geração termoelétrica, cogeração de energia que tenham como combustível de partida a cana-de-açúcar.</p>\n',
            protected: false,
        },
        author: 3,
        featured_media: 318,
        categories: [
            7,
        ],
        tags: [

        ],
    },
    {
        id: 187,
        date: '2020-12-01T05:06:31',
        slug: 'solar',
        status: 'publish',
        type: 'post',
        title: {
            rendered: 'Solar',
        },
        content: {
            rendered: '\n<ul><li>Assessoria na estruturação e desenvolvimento de projetos</li><li>Assessoria regulatória para enquadramento de projeto</li><li>Assessoria junto ao BNDES para obtenção de financiamento do Fundo do Clima</li><li>Avaliação de projetos</li></ul>\n',
            protected: false,
        },
        excerpt: {
            rendered: '<p>Assessoramos analises estratégicas de investimentos a partir dessa fonte voltados para a geração de energia elétrica via projetos de geração distribuída ou projetos de geração dedicado.</p>\n',
            protected: false,
        },
        author: 3,
        featured_media: 320,
        categories: [
            6,
        ],
        tags: [

        ],
    },
];

export default posts;
