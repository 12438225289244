<template>
  <div
    :class="{
      menu: true,
      animated: true,
      fast: true,
      hidden: isHidden,
      slideInLeft: menuIsVisible === true,
      slideOutLeft: menuIsVisible === false,
      [themeColor]: true,
    }"
  >
    <div
      class="postes"
      :style="`background-image: url(${postes})`"
    />
    <div class="content">
      <ul>
        <div
          v-for="(item, index) in menuComputedData"
          :key="index"
        >
          <li
            v-if="item.submenu"
            class="parent withSubmenu"
            @click="openSubmenu"
          >
            <svg
              :class="['arrow', themeColor]"
              xmlns="http://www.w3.org/2000/svg"
              width="38.238"
              height="44.275"
              viewBox="0 0 38.238 44.275"
            >
              <path
                data-name="arrow"
                d="M38.236,44.275,0,22.138,38.238,0c-.98,1.156-1.869,3.969-2.5,7.922a92.28,92.28,0,0,0-1,14.215,92.277,92.277,0,0,0,1,14.215c.633,3.95,1.522,6.763,2.5,7.921Z"
                transform="translate(38.238 44.275) rotate(180)"
              />
            </svg>
            <p>{{ item.title }}</p>
            <ul
              v-if="item.submenu"
              class="submenu"
            >
              <router-link
                v-for="(submenuItem, submenuIndex) in item.submenu"
                :key="submenuIndex"
                tag="li"
                :to="submenuItem.link"
                active-class="active"
                exact
              >
                <svg
                  :class="['arrow', themeColor]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.238"
                  height="44.275"
                  viewBox="0 0 38.238 44.275"
                >
                  <path
                    data-name="arrow"
                    d="M38.236,44.275,0,22.138,38.238,0c-.98,1.156-1.869,3.969-2.5,7.922a92.28,92.28,0,0,0-1,14.215,92.277,92.277,0,0,0,1,14.215c.633,3.95,1.522,6.763,2.5,7.921Z"
                    transform="translate(38.238 44.275) rotate(180)"
                  />
                </svg>
                <p>{{ submenuItem.title }}</p>
              </router-link>
            </ul>
          </li>

          <router-link
            v-else
            tag="li"
            :to="item.link"
            active-class="active"
            class="parent"
            exact
            @click="openSubmenu"
          >
            <svg
              :class="['arrow', themeColor]"
              xmlns="http://www.w3.org/2000/svg"
              width="38.238"
              height="44.275"
              viewBox="0 0 38.238 44.275"
            >
              <path
                data-name="arrow"
                d="M38.236,44.275,0,22.138,38.238,0c-.98,1.156-1.869,3.969-2.5,7.922a92.28,92.28,0,0,0-1,14.215,92.277,92.277,0,0,0,1,14.215c.633,3.95,1.522,6.763,2.5,7.921Z"
                transform="translate(38.238 44.275) rotate(180)"
              />
            </svg>
            <p>{{ item.title }}</p>
          </router-link>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapState, mapActions } from 'vuex';
import defaultMenu from '@/cache/menu';

export default {
    name: 'Menu',
    props: {
        menuData: {
            type: Array,
            default() {
                return [{
                    id: 1,
                    title: 'home',
                    link: { name: 'home' },
                }, {
                    id: 2,
                    title: 'Sobre a Cone',
                    link: { name: 'post', params: { slug: 'a-cone' } },
                }, {
                    id: 3,
                    title: 'Últimas atualizações',
                    link: { name: 'blog', params: { slug: 'a-cone' } },
                }, {
                    id: 4,
                    title: 'Biomassa/Biometano',
                    link: { name: 'blog', query: { category: 'biomassa-biometano' } },
                }, {
                    id: 5,
                    title: 'Solar',
                    link: { name: 'blog', query: { category: 'energia-solar' } },
                }, {
                    id: 6,
                    title: 'Gás Natural',
                    link: { name: 'blog', query: { category: 'gas-natural' } },
                }];
            },
        },
    },
    data() {
        return {
            postes: require('@/assets/postes.svg'),
            iconArrow: require('@/assets/icon-arrow.svg'),
            isHidden: true,
            defaultMenu,
        };
    },
    computed: {
        ...mapState(['menuIsVisible', 'themeColor']),
        ...mapState('posts', ['menu']),
        menuComputedData() {
            const data = [];
            const currentMenu = this.menu.length ? this.menu : this.defaultMenu;

            currentMenu.forEach((item) => {
                const { ID } = item;
                const { title } = item;
                const splittedURL = item.url.split('/');
                const lastParam = splittedURL[splittedURL.length - 2];
                const parentId = parseInt(item.menu_item_parent, 10);

                let link = item.url;

                if (item.object === 'category') {
                    link = { name: 'blog', query: { category: lastParam } };
                }

                if (item.object === 'post' || item.object === 'page') {
                    link = { name: 'post', params: { slug: lastParam } };
                }

                if (parentId) {
                    const parent = data.find((i) => i.id === parentId);

                    if (parent.submenu) {
                        parent.submenu.push({
                            id: ID, title, link,
                        });
                    } else {
                        parent.submenu = [{
                            id: ID, title, link,
                        }];
                    }
                } else {
                    data.push({
                        id: ID, title, link,
                    });
                }
            });

            return data;
        },
    },
    watch: {
        menuIsVisible(value) {
            setTimeout(() => {
                this.isHidden = !value;
            }, 800);
        },
    },
    created() {
        this.getMenu('main');
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'menuAnimated']),
        ...mapActions('posts', ['getMenu']),
        openSubmenu(event) {
            const parent = event.currentTarget;
            const submenu = parent.querySelector('.submenu');
            const items = submenu.querySelectorAll('li');

            if (!submenu.style.height) {
                submenu.style.height = `${80 * items.length}px`;
            } else {
                submenu.style.height = '';
            }
        },
    },
};
</script>
