import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const PostPage = () => import('@/views/PostPage.vue');
const PageNotFound = () => import('@/views/page-not-found.vue');
const Blog = () => import('@/views/blog.vue');
const Contact = () => import('@/views/contact.vue');

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/posts/:slug',
        name: 'post',
        component: PostPage,
    },
    {
        path: '/posts',
        name: 'blog',
        params: ['category'],
        component: Blog,
    },
    {
        path: '/contato',
        name: 'contact',
        component: Contact,
    },
    {
        path: '/*',
        name: 'page-not-found',
        component: PageNotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
