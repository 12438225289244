const defaultMedias = [
    {
        id: 323,
        date: '2024-11-04T13:11:50',
        date_gmt: '2024-11-04T13:11:50',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min.png',
        },
        modified: '2024-11-04T13:11:50',
        modified_gmt: '2024-11-04T13:11:50',
        slug: 'biometano-min',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/biometano/biometano-min/',
        title: {
            rendered: 'biometano-min',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min.png\'><img width="300" height="131" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-300x131.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-300x131.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-700x306.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-768x336.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-1536x672.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-1568x686.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 700,
            file: '2020/12/biometano-min.png',
            sizes: {
                medium: {
                    file: 'biometano-min-300x131.png',
                    width: 300,
                    height: 131,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-300x131.png',
                },
                large: {
                    file: 'biometano-min-700x306.png',
                    width: 700,
                    height: 306,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-700x306.png',
                },
                thumbnail: {
                    file: 'biometano-min-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-150x150.png',
                },
                medium_large: {
                    file: 'biometano-min-768x336.png',
                    width: 768,
                    height: 336,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-768x336.png',
                },
                '1536x1536': {
                    file: 'biometano-min-1536x672.png',
                    width: 1536,
                    height: 672,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-1536x672.png',
                },
                'post-thumbnail': {
                    file: 'biometano-min-1568x686.png',
                    width: 1568,
                    height: 686,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min-1568x686.png',
                },
                full: {
                    file: 'biometano-min.png',
                    width: 1600,
                    height: 700,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 265,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/biometano-min.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/323',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=323',
                },
            ],
        },
    },
    {
        id: 322,
        date: '2024-11-04T13:11:40',
        date_gmt: '2024-11-04T13:11:40',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica.png',
        },
        modified: '2024-11-04T13:11:40',
        modified_gmt: '2024-11-04T13:11:40',
        slug: 'conexao-eletrica-2',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/biometano/conexao-eletrica-2/',
        title: {
            rendered: 'conexao-eletrica',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica.png\'><img width="300" height="150" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-300x150.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-300x150.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-700x350.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-768x384.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-1536x768.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-1568x784.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 800,
            file: '2020/12/conexao-eletrica.png',
            sizes: {
                medium: {
                    file: 'conexao-eletrica-300x150.png',
                    width: 300,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-300x150.png',
                },
                large: {
                    file: 'conexao-eletrica-700x350.png',
                    width: 700,
                    height: 350,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-700x350.png',
                },
                thumbnail: {
                    file: 'conexao-eletrica-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-150x150.png',
                },
                medium_large: {
                    file: 'conexao-eletrica-768x384.png',
                    width: 768,
                    height: 384,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-768x384.png',
                },
                '1536x1536': {
                    file: 'conexao-eletrica-1536x768.png',
                    width: 1536,
                    height: 768,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-1536x768.png',
                },
                'post-thumbnail': {
                    file: 'conexao-eletrica-1568x784.png',
                    width: 1568,
                    height: 784,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-1568x784.png',
                },
                full: {
                    file: 'conexao-eletrica.png',
                    width: 1600,
                    height: 800,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 265,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/322',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=322',
                },
            ],
        },
    },
    {
        id: 320,
        date: '2024-10-28T18:06:03',
        date_gmt: '2024-10-28T18:06:03',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled.png',
        },
        modified: '2024-10-28T18:06:03',
        modified_gmt: '2024-10-28T18:06:03',
        slug: 'solar-upscaled',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/solar/solar-upscaled/',
        title: {
            rendered: 'solar-upscaled',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled.png\'><img width="300" height="131" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-300x131.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-300x131.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-700x306.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-768x336.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-1536x672.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-1568x686.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 700,
            file: '2020/12/solar-upscaled.png',
            sizes: {
                medium: {
                    file: 'solar-upscaled-300x131.png',
                    width: 300,
                    height: 131,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-300x131.png',
                },
                large: {
                    file: 'solar-upscaled-700x306.png',
                    width: 700,
                    height: 306,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-700x306.png',
                },
                thumbnail: {
                    file: 'solar-upscaled-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-150x150.png',
                },
                medium_large: {
                    file: 'solar-upscaled-768x336.png',
                    width: 768,
                    height: 336,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-768x336.png',
                },
                '1536x1536': {
                    file: 'solar-upscaled-1536x672.png',
                    width: 1536,
                    height: 672,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-1536x672.png',
                },
                'post-thumbnail': {
                    file: 'solar-upscaled-1568x686.png',
                    width: 1568,
                    height: 686,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-1568x686.png',
                },
                full: {
                    file: 'solar-upscaled.png',
                    width: 1600,
                    height: 700,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 187,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/320',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=320',
                },
            ],
        },
    },
    {
        id: 319,
        date: '2024-10-28T17:58:09',
        date_gmt: '2024-10-28T17:58:09',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min.png',
        },
        modified: '2024-10-28T17:58:09',
        modified_gmt: '2024-10-28T17:58:09',
        slug: 'solar-upscaled-min',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/solar/solar-upscaled-min/',
        title: {
            rendered: 'solar-upscaled-min',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min.png\'><img width="300" height="131" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-300x131.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-300x131.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-700x306.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-768x336.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-1536x672.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-2048x896.png 2048w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-1568x686.png 1568w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 3200,
            height: 1400,
            file: '2020/12/solar-upscaled-min.png',
            sizes: {
                medium: {
                    file: 'solar-upscaled-min-300x131.png',
                    width: 300,
                    height: 131,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-300x131.png',
                },
                large: {
                    file: 'solar-upscaled-min-700x306.png',
                    width: 700,
                    height: 306,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-700x306.png',
                },
                thumbnail: {
                    file: 'solar-upscaled-min-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-150x150.png',
                },
                medium_large: {
                    file: 'solar-upscaled-min-768x336.png',
                    width: 768,
                    height: 336,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-768x336.png',
                },
                '1536x1536': {
                    file: 'solar-upscaled-min-1536x672.png',
                    width: 1536,
                    height: 672,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-1536x672.png',
                },
                '2048x2048': {
                    file: 'solar-upscaled-min-2048x896.png',
                    width: 2048,
                    height: 896,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-2048x896.png',
                },
                'post-thumbnail': {
                    file: 'solar-upscaled-min-1568x686.png',
                    width: 1568,
                    height: 686,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min-1568x686.png',
                },
                full: {
                    file: 'solar-upscaled-min.png',
                    width: 3200,
                    height: 1400,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 187,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-upscaled-min.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/319',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=319',
                },
            ],
        },
    },
    {
        id: 318,
        date: '2024-10-28T17:53:09',
        date_gmt: '2024-10-28T17:53:09',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min.png',
        },
        modified: '2024-10-28T17:53:09',
        modified_gmt: '2024-10-28T17:53:09',
        slug: 'bioeletricidade-2-min',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/bioeletricidade/bioeletricidade-2-min/',
        title: {
            rendered: 'bioeletricidade-2-min',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min.png\'><img width="300" height="150" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-300x150.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-300x150.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-700x350.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-768x384.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-1536x768.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-1568x784.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 800,
            file: '2020/12/bioeletricidade-2-min.png',
            sizes: {
                medium: {
                    file: 'bioeletricidade-2-min-300x150.png',
                    width: 300,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-300x150.png',
                },
                large: {
                    file: 'bioeletricidade-2-min-700x350.png',
                    width: 700,
                    height: 350,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-700x350.png',
                },
                thumbnail: {
                    file: 'bioeletricidade-2-min-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-150x150.png',
                },
                medium_large: {
                    file: 'bioeletricidade-2-min-768x384.png',
                    width: 768,
                    height: 384,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-768x384.png',
                },
                '1536x1536': {
                    file: 'bioeletricidade-2-min-1536x768.png',
                    width: 1536,
                    height: 768,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-1536x768.png',
                },
                'post-thumbnail': {
                    file: 'bioeletricidade-2-min-1568x784.png',
                    width: 1568,
                    height: 784,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min-1568x784.png',
                },
                full: {
                    file: 'bioeletricidade-2-min.png',
                    width: 1600,
                    height: 800,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 190,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/bioeletricidade-2-min.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/318',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=318',
                },
            ],
        },
    },
    {
        id: 317,
        date: '2024-10-28T17:52:31',
        date_gmt: '2024-10-28T17:52:31',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min.png',
        },
        modified: '2024-10-28T17:52:31',
        modified_gmt: '2024-10-28T17:52:31',
        slug: 'gas-natural-min',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/gas-natural/gas-natural-min/',
        title: {
            rendered: 'gas-natural-min',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min.png\'><img width="300" height="131" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-300x131.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-300x131.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-700x306.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-768x336.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-1536x672.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-1568x686.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 700,
            file: '2020/12/gas-natural-min.png',
            sizes: {
                medium: {
                    file: 'gas-natural-min-300x131.png',
                    width: 300,
                    height: 131,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-300x131.png',
                },
                large: {
                    file: 'gas-natural-min-700x306.png',
                    width: 700,
                    height: 306,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-700x306.png',
                },
                thumbnail: {
                    file: 'gas-natural-min-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-150x150.png',
                },
                medium_large: {
                    file: 'gas-natural-min-768x336.png',
                    width: 768,
                    height: 336,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-768x336.png',
                },
                '1536x1536': {
                    file: 'gas-natural-min-1536x672.png',
                    width: 1536,
                    height: 672,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-1536x672.png',
                },
                'post-thumbnail': {
                    file: 'gas-natural-min-1568x686.png',
                    width: 1568,
                    height: 686,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min-1568x686.png',
                },
                full: {
                    file: 'gas-natural-min.png',
                    width: 1600,
                    height: 700,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 192,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/gas-natural-min.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/317',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=317',
                },
            ],
        },
    },
    {
        id: 316,
        date: '2024-10-28T17:52:00',
        date_gmt: '2024-10-28T17:52:00',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min.png',
        },
        modified: '2024-10-28T17:52:00',
        modified_gmt: '2024-10-28T17:52:00',
        slug: 'conexao-eletrica-min',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/conexao-eletrica/conexao-eletrica-min/',
        title: {
            rendered: 'conexao-eletrica-min',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min.png\'><img width="300" height="150" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-300x150.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-300x150.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-700x350.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-768x384.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-1536x768.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-1568x784.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 800,
            file: '2020/12/conexao-eletrica-min.png',
            sizes: {
                medium: {
                    file: 'conexao-eletrica-min-300x150.png',
                    width: 300,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-300x150.png',
                },
                large: {
                    file: 'conexao-eletrica-min-700x350.png',
                    width: 700,
                    height: 350,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-700x350.png',
                },
                thumbnail: {
                    file: 'conexao-eletrica-min-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-150x150.png',
                },
                medium_large: {
                    file: 'conexao-eletrica-min-768x384.png',
                    width: 768,
                    height: 384,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-768x384.png',
                },
                '1536x1536': {
                    file: 'conexao-eletrica-min-1536x768.png',
                    width: 1536,
                    height: 768,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-1536x768.png',
                },
                'post-thumbnail': {
                    file: 'conexao-eletrica-min-1568x784.png',
                    width: 1568,
                    height: 784,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min-1568x784.png',
                },
                full: {
                    file: 'conexao-eletrica-min.png',
                    width: 1600,
                    height: 800,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 203,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/conexao-eletrica-min.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/316',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=316',
                },
            ],
        },
    },
    {
        id: 314,
        date: '2024-10-28T17:51:00',
        date_gmt: '2024-10-28T17:51:00',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação.jpg',
        },
        modified: '2024-10-28T17:51:00',
        modified_gmt: '2024-10-28T17:51:00',
        slug: 'regulac%cc%a7a%cc%83o',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/regulatorio/regulac%cc%a7a%cc%83o/',
        title: {
            rendered: 'Regulação',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação.jpg\'><img width="300" height="300" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-300x300.jpg" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-300x300.jpg 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-700x700.jpg 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-150x150.jpg 150w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-768x768.jpg 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação.jpg 1024w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/jpeg',
        media_details: {
            width: 1024,
            height: 1024,
            file: '2020/12/Regulação.jpg',
            sizes: {
                medium: {
                    file: 'Regulação-300x300.jpg',
                    width: 300,
                    height: 300,
                    mime_type: 'image/jpeg',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-300x300.jpg',
                },
                large: {
                    file: 'Regulação-700x700.jpg',
                    width: 700,
                    height: 700,
                    mime_type: 'image/jpeg',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-700x700.jpg',
                },
                thumbnail: {
                    file: 'Regulação-150x150.jpg',
                    width: 150,
                    height: 150,
                    mime_type: 'image/jpeg',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-150x150.jpg',
                },
                medium_large: {
                    file: 'Regulação-768x768.jpg',
                    width: 768,
                    height: 768,
                    mime_type: 'image/jpeg',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação-768x768.jpg',
                },
                full: {
                    file: 'Regulação.jpg',
                    width: 1024,
                    height: 1024,
                    mime_type: 'image/jpeg',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação.jpg',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 201,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/Regulação.jpg',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/314',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=314',
                },
            ],
        },
    },
    {
        id: 312,
        date: '2024-10-28T17:49:48',
        date_gmt: '2024-10-28T17:49:48',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min.png',
        },
        modified: '2024-10-28T17:51:31',
        modified_gmt: '2024-10-28T17:51:31',
        slug: 'bioeletricidade-min',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/descarbonizacao/bioeletricidade-min/',
        title: {
            rendered: 'descarbonizacao',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min.png\'><img width="300" height="150" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-300x150.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-300x150.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-700x350.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-768x384.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-1536x768.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-1568x784.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 800,
            file: '2024/09/bioeletricidade-min.png',
            sizes: {
                medium: {
                    file: 'bioeletricidade-min-300x150.png',
                    width: 300,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-300x150.png',
                },
                large: {
                    file: 'bioeletricidade-min-700x350.png',
                    width: 700,
                    height: 350,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-700x350.png',
                },
                thumbnail: {
                    file: 'bioeletricidade-min-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-150x150.png',
                },
                medium_large: {
                    file: 'bioeletricidade-min-768x384.png',
                    width: 768,
                    height: 384,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-768x384.png',
                },
                '1536x1536': {
                    file: 'bioeletricidade-min-1536x768.png',
                    width: 1536,
                    height: 768,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-1536x768.png',
                },
                'post-thumbnail': {
                    file: 'bioeletricidade-min-1568x784.png',
                    width: 1568,
                    height: 784,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min-1568x784.png',
                },
                full: {
                    file: 'bioeletricidade-min.png',
                    width: 1600,
                    height: 800,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 297,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2024/09/bioeletricidade-min.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/312',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=312',
                },
            ],
        },
    },
    {
        id: 310,
        date: '2024-10-25T13:35:39',
        date_gmt: '2024-10-25T13:35:39',
        guid: {
            rendered: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar.png',
        },
        modified: '2024-10-25T13:35:39',
        modified_gmt: '2024-10-25T13:35:39',
        slug: 'solar-3',
        status: 'inherit',
        type: 'attachment',
        link: 'https://admin-cone.coneconsultoria.com.br/solar/solar-3/',
        title: {
            rendered: 'solar',
        },
        author: 3,
        comment_status: 'open',
        ping_status: 'closed',
        template: '',
        meta: [

        ],
        description: {
            rendered: '<p class="attachment"><a href=\'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar.png\'><img width="300" height="131" src="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-300x131.png" class="attachment-medium size-medium" alt="" loading="lazy" srcset="https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-300x131.png 300w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-700x306.png 700w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-768x336.png 768w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-1536x672.png 1536w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-1568x686.png 1568w, https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar.png 1600w" sizes="(max-width: 34.9rem) calc(100vw - 2rem), (max-width: 53rem) calc(8 * (100vw / 12)), (min-width: 53rem) calc(6 * (100vw / 12)), 100vw" /></a></p>\n',
        },
        caption: {
            rendered: '',
        },
        alt_text: '',
        media_type: 'image',
        mime_type: 'image/png',
        media_details: {
            width: 1600,
            height: 700,
            file: '2020/12/solar.png',
            sizes: {
                medium: {
                    file: 'solar-300x131.png',
                    width: 300,
                    height: 131,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-300x131.png',
                },
                large: {
                    file: 'solar-700x306.png',
                    width: 700,
                    height: 306,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-700x306.png',
                },
                thumbnail: {
                    file: 'solar-150x150.png',
                    width: 150,
                    height: 150,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-150x150.png',
                },
                medium_large: {
                    file: 'solar-768x336.png',
                    width: 768,
                    height: 336,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-768x336.png',
                },
                '1536x1536': {
                    file: 'solar-1536x672.png',
                    width: 1536,
                    height: 672,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-1536x672.png',
                },
                'post-thumbnail': {
                    file: 'solar-1568x686.png',
                    width: 1568,
                    height: 686,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar-1568x686.png',
                },
                full: {
                    file: 'solar.png',
                    width: 1600,
                    height: 700,
                    mime_type: 'image/png',
                    source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar.png',
                },
            },
            image_meta: {
                aperture: '0',
                credit: '',
                camera: '',
                caption: '',
                created_timestamp: '0',
                copyright: '',
                focal_length: '0',
                iso: '0',
                shutter_speed: '0',
                title: '',
                orientation: '0',
                keywords: [

                ],
            },
        },
        post: 187,
        source_url: 'https://admin-cone.coneconsultoria.com.br/wp-content/uploads/2020/12/solar.png',
        _links: {
            self: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media/310',
                },
            ],
            collection: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/media',
                },
            ],
            about: [
                {
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/types/attachment',
                },
            ],
            author: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/users/3',
                },
            ],
            replies: [
                {
                    embeddable: true,
                    href: 'https://admin-cone.coneconsultoria.com.br/wp-json/wp/v2/comments?post=310',
                },
            ],
        },
    },
];
export default defaultMedias;
