<template>
  <div id="app">
    <main-header />
    <Menu />
    <router-view />
    <main-footer />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import Menu from '@/components/Menu.vue';
import MainFooter from '@/components/MainFooter.vue';
// import Loader from '@/components/Loader.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        Menu,
        MainHeader,
        MainFooter,
    // Loader,
    },
    data() {
        return {
            fields: {
                _fields: [
                    'author',
                    'content',
                    'media',
                    'jetpack_featured_media_url',
                    'slug',
                    'id',
                    'title',
                    'status',
                    'type',
                    'date',
                    'excerpt',

                    'featured_media',
                    'categories',
                    'tags',
                ],
                status: 'publish',
                page: 1,
                per_page: 10,
            },
        };
    },
    created() {
        this.getAuthors({});
        this.getMenu();
        this.getSocialMedias();
        this.getSettings();
        if (!this.posts.length) this.getPosts(this.fields);
        if (!this.pages.length) this.getPages(this.fields);
    },
    computed: {
        ...mapState('posts', ['posts', 'pages']),
    },
    methods: {
        ...mapActions('posts', [
            'getAuthors',
            'getMenu',
            'getSlides',
            'getSocialMedias',
            'getSettings',
            'getMedia',
            'getPosts',
            'getPages',
        ]),
    },
};
</script>

<style>
@font-face {
  font-family: geometric;
  src: url("./fonts/geometric_BT.ttf");
}
@font-face {
  font-family: human-bold;
  src: url("./fonts/Humanst521-BT-Roman.ttf");
}
@font-face {
  font-family: human-light;
  src: url("./fonts/humanst521-lt-bt-light.ttf");
}
</style>
