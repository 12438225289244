<template>
  <footer>
    <div class="group">
      <img
        class="logo"
        :src="logo"
        alt="Cone logo branca"
      >
      <p>© 2024 Todos os direitos reservados</p>
    </div>
  </footer>
</template>

<script>
/* eslint-disable global-require */

export default {
    name: 'MainFooter',
    data() {
        return {
            logo: require('@/assets/logo-white-horizontal.svg'),
        };
    },
};
</script>
