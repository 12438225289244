/* eslint-disable no-param-reassign */
import API from '@/controllers/database';
import defaultPages from '@/cache/pages';
import defaultPosts from '@/cache/posts';
import defaultCategories from '@/cache/categories';
import defaultSlides from '@/cache/slides';
import defaultSlideButtons from '@/cache/slideButtons';
import defaultAuthors from '@/cache/users';
import defaultMedias from '@/cache/medias';

const getPostsWithCategories = (posts, categories, authors = []) => posts.map((post) => {
    post.author = authors.find((a) => a.id === post.author);
    post.categories = post.categories.map((id) => categories.find((category) => category.id === id));
    return post;
});

const getSlidesWithButtons = (slides, buttons) => {
    if (buttons && buttons.length) {
        slides.map((slide) => {
            // eslint-disable-next-line no-param-reassign
            slide.buttons = buttons.filter(
                (button) => slide.id === button.slide_id,
            );
            return slide;
        });
    }

    return slides;
};

export default {
    namespaced: true,
    state: {
        posts: [],
        pages: [],
        authors: [],
        currentPost: {},
        categories: [],
        slides: [],
        socialMedias: [],
        menu: [],
        slideButtons: [],
        settings: [],
        medias: [],
    },
    mutations: {
        initSettings(state, settings) {
            if (settings.length) {
                state.settings = settings.filter((setting) => setting.option.includes('SECTION'));
            }
        },
        initPosts(state, posts) {
            if (!state.posts.length) state.posts = [...posts];
        },
        initPages(state, pages) {
            if (!state.pages.length) state.pages = pages;
        },
        initCategories(state, categories) {
            state.categories = categories;
        },
        initAuthors(state, authors) {
            state.authors = authors;
        },
        initSlides(state, slides) {
            state.slides = slides;
        },
        initSlideButtons(state, buttons) {
            state.slideButtons = buttons;
        },
        initSocialMedias(state, socialMedias) {
            state.socialMedias = socialMedias;
        },
        initMenu(state, menu) {
            state.menu = menu.sort((a, b) => a.menu_order - b.menu_order);
        },
        initMedias(state, medias) {
            const obj = {};

            medias.forEach((media) => {
                if (media.media_details.sizes.large) {
                    obj[media.id] = media.media_details.sizes.large.source_url;
                } else {
                    obj[media.id] = media.media_details.sizes.full.source_url;
                }
            });

            state.medias = obj;
        },
        setCurrentPost(state, post) {
            /**
             * I think that post returns an array of posts instead of a single post
             * To test
             *
             * */
            state.currentPost = post;
        },
    },
    actions: {
        getSettings({ commit }, params = {}) {
            return API.requestPlugin('GET', params, 'settings').then((settings) => {
                commit('initSettings', JSON.parse(settings));
                return JSON.parse(settings);
            });
        },
        getSlides({ commit, dispatch }, params = {}) {
            commit('initSlides', getSlidesWithButtons(defaultSlides, defaultSlideButtons));
            return API.requestPlugin('GET', params, 'slides').then((response) => {
                const slides = JSON.parse(response);
                dispatch('getButtons').then((buttons) => {
                    const slidesWithButtons = getSlidesWithButtons(slides, buttons);
                    commit('initSlides', slidesWithButtons);
                });
                return slides;
            });
        },
        getButtons({ commit }, params = {}) {
            commit('initSlideButtons', defaultSlideButtons);
            return API.requestPlugin('GET', params, 'slide_buttons').then(
                (response) => {
                    commit('initSlideButtons', JSON.parse(response));
                    return JSON.parse(response);
                },
            );
        },
        getSocialMedias({ commit }, params = {}) {
            return API.requestPlugin('GET', params, 'social_medias').then(
                (response) => {
                    const socialMedias = JSON.parse(response);
                    commit('initSocialMedias', socialMedias);
                    return socialMedias;
                },
            );
        },
        getMedia({ commit }, id = '') {
            return API.request('GET', {}, `media/${id}`).then((medias) => {
                let values = medias;
                if (!Array.isArray(medias)) values = [medias];
                commit('initMedias', values);
                return values;
            });
        },
        getMedias({ commit }) {
            return API.request('GET', {}, 'media').then((medias) => {
                commit('initMedias', medias);
                return medias;
            });
        },
        getMenu({ commit }, menuName) {
            return API.requestPlugin(
                'GET',
                {},
                `menu_nav_wp/${menuName || 'Main'}`,
            ).then((response) => {
                commit('initMenu', response);
                return response;
            });
        },

        async getPosts({ commit, dispatch }, params) {
            commit('initMedias', defaultMedias);
            commit('initPosts', getPostsWithCategories(defaultPosts, defaultCategories, defaultAuthors));
            const response = await API.request('GET', params, 'posts');
            const categories = await dispatch('getCategories');
            const authors = await dispatch('getAuthors', {});

            if (authors) {
                const posts = getPostsWithCategories(response, categories, authors);
                const validMedias = posts.filter((post) => post.featured_media !== 0).map(
                    (post) => post.featured_media,
                );

                // const promises = validMedias.map((media) => dispatch('getMedia', media));
                // const medias = (await Promise.all(promises)).flatMap((media) => media);

                // to make a cache of the medias
                // posts.forEach((post) => {
                //     console.log(post.featured_media, post.slug);
                // });

                // commit('initMedias', medias);
                commit('initPosts', posts);
                return posts;
            }
            return [];
        },

        getPages({ commit, dispatch }, params) {
            commit('initPages', defaultPages);
            return API.request('GET', params, 'pages').then((pages) => {
                if (pages.length === 1) commit('setCurrentPost', pages);
                else commit('initPages', pages);
                return pages;
            });
        },

        async getPost({ commit, dispatch }, params) {
            // check if the post is already in the store before making a request
            // set cache state
            let posts = await API.request('GET', params, 'posts');
            const categories = await dispatch('getCategories');
            const authors = await dispatch('getAuthors', {});

            if (authors) {
                posts = posts.map(
                    (post) => {
                        post.author = authors.find((a) => a.id === post.author);
                        post.categories = post.categories.map((id) => categories.find((category) => category.id === id));
                        return post;
                    },
                );

                commit('setCurrentPost', posts);
                return posts;
            }
            return [];
        },
        async getCategories(
            { commit },
            params = {
                _fields: ['slug', 'name', 'id'],
            },
        ) {
            commit('initCategories', defaultCategories);
            const categories = await API.request('GET', params, 'categories');
            commit('initCategories', categories);

            return categories;
        },
        async getAuthors({ state, commit }, params) {
            if (state.authors && state.authors.length) return state.authors;

            const users = await API.request('GET', params, 'users');
            commit('initAuthors', users);

            return users;
        },
    },
};
