<template>
  <div
    :class="{slide: true, noImage: computedSlides[currentSlide] ? !computedSlides[currentSlide].imageURL : true}"
  >
    <span
      v-if="currentSlide > 0"
      class="previous"
      @click="previous"
    ><img
      :src="chevron"
      alt="chevron"
    ></span>
    <span
      v-if="currentSlide < computedSlides.length - 1"
      class="next"
      @click="next"
    ><img
      :src="chevron"
      alt="chevron"
    ></span>
    <figure
      v-for="(slide, index) in computedSlides"
      v-show="computedSlides.length"
      :key="index"
      :style="`background-image: url(${slide.imageURL})`"
      :class="{animated: true, fast: true, slideInRight: index === currentSlide, slideOutRight: index !== currentSlide, [themeColor]: true}"
    >
      <div class="background" />
      <figcaption>
        <h1 v-if="slide.title">
          {{ slide.title }}
        </h1>
        <p v-if="slide.description">
          {{ slide.description }}
        </p>
        <div class="buttonGroup">
          <a
            v-for="(button, i) in slide.buttons"
            :key="i"
            class="button transparent white rounded"
            type="button"
            :href="button.link | validateURL"
          >
            {{ button.label }}
          </a>
        </div>
      </figcaption>
    </figure>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapState } from 'vuex';

export default {
    name: 'Slide',
    filters: {
        validateURL(value) {
            let url = false;
            try {
                url = new URL(value);
                return url;
            } catch (e) {
                return `${value}`;
            }
        },
    },
    props: {
        slides: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            chevron: require('@/assets/down-chevron.svg'),
            currentSlide: 0,
        };
    },
    computed: {
        ...mapState(['themeColor']),
        computedSlides() {
            return this.slides;
        },
    },
    methods: {
        next() {
            if (this.currentSlide >= 0 && this.currentSlide < this.computedSlides.length - 1) this.currentSlide += 1;
            this.$emit('currentSlide', this.currentSlide);
        },
        previous() {
            if (this.currentSlide > 0 && this.currentSlide <= this.computedSlides.length - 1) this.currentSlide -= 1;
            this.$emit('currentSlide', this.currentSlide);
        },
    },
};
</script>

<style scoped>

</style>
