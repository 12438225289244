import Vue from 'vue';
import Vuex from 'vuex';
import posts from './modules/posts';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        animated: false,
        menuIsVisible: false,
        isBlog: false,
        loader: true,
        themeColor: 'orange',
    },
    mutations: {
        setMenuVisibility(state, visibility) {
            state.menuIsVisible = visibility;
        },
        setAnimated(state, animated) {
            state.animated = animated;
        },
        setHeaderBlog(state, isBlog) {
            state.isBlog = isBlog;
        },
        setLoader(state, loader) {
            state.loader = loader;
        },
        setThemeColor(state, color) {
            state.themeColor = color;
        },
    },
    actions: {
        changeMenuVisibility({ commit }, visibility) {
            if (visibility) document.body.classList.add('menu');
            else document.body.classList.remove('menu');
            commit('setMenuVisibility', visibility);
        },
        changeAnimated({ commit }, animated) {
            commit('setMenuAnimated', animated);
        },
        changeIsBlog({ commit }, isBlog) {
            commit('setHeaderBlog', isBlog);
        },
        changeLoaderVisibility({ commit }, visibility) {
            commit('setLoader', visibility);
        },
        changeThemeColor({ commit }, category) {
            const colorByCateogory = {
                'biomassa-biometano': 'green',
                'gas-natural': 'blue',
                'energia-solar': 'yellow',
                'conexao-eletrica': 'blue-grey',
            };

            commit('setThemeColor', colorByCateogory[category]);
        },
    },
    modules: {
        posts,
    },
});
