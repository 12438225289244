export default {
    url: `${process.env.VUE_APP_ROOT_API}/wp-json/wp/v2/`,
    parameters(newParams, type) {
        const params = type !== 'UPLOAD' ? new URLSearchParams() : new FormData();

        Object.keys(newParams).forEach((key) => params.append(key, newParams[key]));
        return params;
    },
    async request(method, params, entity) {
        const url = new URL(this.url + entity);
        const options = { method };

        if (method === 'GET' && params) {
            url.search = this.parameters(params);
        } else {
            options.body = params instanceof FormData ? params : this.parameters(params, method);
        }

        const response = await fetch(url, options);
        return response.json();
    },

    async requestPlugin(method, params, entity) {
        const uri = `${process.env.VUE_APP_ROOT_API}/wp-json/lv/v1/`;
        const url = new URL(uri + entity);
        const options = { method };

        if (method === 'GET') {
            url.search = this.parameters(params);
        } else {
            options.body = params instanceof FormData ? params : this.parameters(params, method);
        }

        const response = await fetch(url, options);
        return response.json();
    },
};
